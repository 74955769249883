import request from '@/utils/request'


//数据字典
export function getDict() {
  return request({
    url: 'dict/dict',
    method: 'post'
  })

}
export function fileUpload(data) {
  return request({
    url: 'upload/file',
    method: 'post',
    data: data
  })
}

